.rich-text-content p,
.rich-text-content ul,
.rich-text-content ol,
.rich-text-content pre,
.rich-text-content blockquote {
    margin: 1rem 0;
}

.rich-text-content ul,
.rich-text-content ol {
    padding: 0 2.5rem;
}

.rich-text-content ul p,
.rich-text-content ol p,
.rich-text-content li ol,
.rich-text-content li ul
{
    margin: 0;
}

.rich-text-content pre {
    font-family: monospace;
    line-height: 1.25em;
}

.rich-text-content code {
    border-radius: 0.25rem;
    padding: 0.125em 0.25em;
}

.rich-text-content pre code  {
    border-radius: 0.25em;
    padding: 0.75em 1em;
}

.rich-text-content pre code, .rich-text-content code {
    font-size: 0.95em;
}

.rich-text-content blockquote {
    padding: 0.25em 1em;
    border-radius: 0.25em;
    border-left: 3px solid transparent;
}

.dashboard-light-theme .rich-text-content code,
.dashboard-light-theme .rich-text-content pre code,
.dashboard-light-theme .rich-text-content blockquote {
    color: #2f3337;
    background: #f6f6f6;
}

.dashboard-dark-theme .rich-text-content code,
.dashboard-dark-theme .rich-text-content pre code,
.dashboard-dark-theme .rich-text-content blockquote {
    color: #fff;
    background: #333D49;
}

.dashboard-light-theme .rich-text-content blockquote {
    border-left-color: #2f3337;
}

.dashboard-dark-theme .rich-text-content blockquote {
    border-left-color: #fff;
}
